import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// 1. Define route components.
// These can be imported from other files
//const Home = { template: '<div>Home</div>' }
//#const About = { template: '<div>About</div>' }

// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.



createApp(App).use(router).use(router).router().mount('#app')
